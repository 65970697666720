/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { PropsWithChildren } from "react";

export const Layout: React.FC<PropsWithChildren> = (props) => {
  return (
    <div css={css``}>
      <div
        css={css`
          max-width: 800px;
          margin: 64px auto 64px;
          padding: 0 18px;
        `}
      >
        {props.children}
      </div>
    </div>
  );
};
