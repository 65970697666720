/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { Fragment, useContext, useState } from "react";
import Webcam from "react-webcam";
import { currentPageContext } from "../hooks/current-page-context";
import { Button } from "./button";

export const CameraComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_CAMERA;
}> = ({ item }) => {
  const [currentPage, setCurrentPage] = useContext(currentPageContext);
  const [showCam, setShowCam] = useState(false);
  const webcamRef = React.useRef<Webcam | null>(null);
  const [photo, setPhoto] = React.useState<string | null>(null);
  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setPhoto(imageSrc);
      setShowCam(false);
    }
  }, [webcamRef]);
  if (!item) {
    return null;
  }
  return (
    <Fragment>
      {!photo && (
        <Button onClick={() => setShowCam(true)}>{item.buttonText}</Button>
      )}
      {showCam && (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
          `}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: item.frontCamera ? "user" : "environment",
            }}
            css={css`
              width: 100%;
            `}
          />
          <div
            css={css`
              position: fixed;
              bottom: 132px;
              display: flex;
              align-items: center;
            `}
          >
            <Button inverted onClick={capture}>
              {item.takePictureText}
            </Button>
          </div>
        </div>
      )}
      {photo && (
        <div>
          <img
            src={photo}
            css={css`
              width: 100%;
            `}
          />
          <Button onClick={() => setShowCam(true)}>
            {item.retakePictureText}
          </Button>
          <Button
            onClick={() =>
              item.page &&
              item.page.slug &&
              setCurrentPage &&
              setCurrentPage(item.page.slug)
            }
          >
            {item.continueText}
          </Button>
        </div>
      )}
    </Fragment>
  );
};
