/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { Fragment } from "react";

export const TextInputComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_TEXT_INPUT;
}> = ({ item }) => (
  <div>
    <label>
      <div>{item.label}</div>
      <textarea
        rows={3}
        css={css`
          line-height: 2em;
          padding: 0.5em;
          display: block;
          margin: 0.5em 0;
          width: 100%;
          margin: 32px 0px;
        `}
      ></textarea>
    </label>
  </div>
);
