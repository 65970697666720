/** @jsx jsx */

import { jsx, css } from "@emotion/react";
import React, { Fragment, useContext } from "react";
import { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import ClickNHold from "react-click-n-hold";
import { gsap } from "gsap";
import { currentPageContext } from "../hooks/current-page-context";
import { GreenCam } from "./GreenCam";

export const NaturalCylinder: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_NATURAL_CYLINDER;
}> = ({ item }) => {
  const webcamRef = useRef(null);
  const activeCircle = useRef(null);
  const [currentPage, setCurrentPage] = useContext(currentPageContext);

  const [finished, setFinished] = useState(false);
  const [holdingStatus, setHoldingStatus] = useState("idle");

  const startHold = (e) => {
    gsap.registerPlugin(DrawSVGPlugin);
    gsap.fromTo(
      activeCircle.current,
      10,
      { drawSVG: "0%" },
      {
        drawSVG: "100%",
        ease: "none",
        onComplete: () => {
          setFinished(true);
          setTimeout(() => {
            setCurrentPage && item.page?.slug && setCurrentPage(item.page.slug);
          }, 2000);
        },
      }
    );
  };
  const clickNHold = () => {
    /*doet niks*/
  };
  const endHold = () => {
    gsap.killTweensOf(activeCircle.current);
    gsap.to(activeCircle.current, 0.5, {
      drawSVG: "0%",
      onComplete: () => {
        alert(
          "Geen volledige scan kunnen uitvoeren. Probeer het opnieuw. Houdt hierbij je vinger tijdens het draaien op het scherm totdat de cirkel helemaal vol is."
        );
      },
    });
  };

  useEffect(() => {
    gsap.set(activeCircle.current, {
      display: "block",
      drawSVG: "0%",
      // rotate: -90,
    });

    // if (
    //   window.DeviceOrientationEvent ||
    //   window.OrientationEvent ||
    //   typeof window.onorientationchange != "undefined"
    // ) {
    //   window.addEventListener("deviceorientation", handleOrientation, true);
    // }

    // return () => {
    //   // window.removeEventListener("mousemove", () => {});
    // };
  }, []);

  useEffect(() => {
    gsap.set(activeCircle.current, {
      display: "block",
      drawSVG: "0%",
      // rotate: -90,
    });

    // if (
    //   window.DeviceOrientationEvent ||
    //   window.OrientationEvent ||
    //   typeof window.onorientationchange != "undefined"
    // ) {
    //   window.addEventListener("deviceorientation", handleOrientation, true);
    // }

    // return () => {
    //   // window.removeEventListener("mousemove", () => {});
    // };
  }, []);

  return (
    <Fragment>
      <div
        css={css`
          position: fixed;
          z-index: -1;
          user-select: none;
        `}
      >
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: black;
          `}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: "environment" }}
            css={css`
              width: 100%;
            `}
          />
          {/* <GreenCam interpolation={"linear"} color={"heatmap"} /> */}
        </div>
      </div>
      <div
        css={css`
          position: fixed;
          z-index: 1;
          user-select: none;
        `}
      >
        {!finished && (
          <Fragment>
            <img src="/images/1.png" alt="" className="overlayImage" />
            <svg
              className="overlayCircle"
              x="0px"
              y="0px"
              viewBox="0 0 200 200"
            >
              <circle cx="100" cy="100" r="84.2" />
              <circle
                ref={activeCircle}
                className="activeCircle"
                cx="100"
                cy="100"
                r="84.2"
              />
            </svg>
            <div className="overlayText">
              <div>
                Klik en blijf het scherm vasthouden.
                <br />
                Draai dan rondjes tot de cirkel vol is.
              </div>
            </div>

            <ClickNHold
              time={5} // Time to keep pressing. Default is 2
              onStart={startHold} // Start callback
              onClickNHold={clickNHold} //Timeout callback
              onEnd={endHold}
            >
              <div
                className={`holdOverlay ${
                  holdingStatus === "isHolding" ? "isHolding" : ""
                }`}
              ></div>
            </ClickNHold>
          </Fragment>
        )}
        {finished && <div className={`holdOverlay`}>calculating...</div>}
      </div>
    </Fragment>
  );
};
