/** @jsx jsx */

import { jsx, css } from "@emotion/react";
import { Howl } from "howler";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import { useAnimationFrame } from "../hooks/use-animation-frame";
import ReactHowler from "react-howler";

const shaders = Shaders.create({
  yugo: {
    frag: GLSL`
      precision highp float;
      varying vec2 uv;
      uniform float time, animate;
      vec4 circle (float d, vec4 outerFill, float scale) {
        vec4 color = mix(vec4(1.0, 1.0, 1.0, 0.00), outerFill, smoothstep(0.2 * scale, 0.7 * scale, d));
        //return mix(color, vec4(0.0, 0.0, 0.0, 0.0), smoothstep(0.5 * scale, 1.0 * scale, d));
        return mix(color, vec4(0.0, 0.0, 0.0, 0.0), smoothstep(0.2, 0.5, d));
      }
      vec2 orbit (float amplitude, float period) {
        return vec2(sin(time * period) / 2.0 + 0.5, cos(time * period) / 2.0 + 0.5) * vec2(amplitude, amplitude) + vec2(0.5 - amplitude / 2.0);
      }
      float rand (float x) {
        return fract(sin(x)*1.0);
      }
      float noise (float x) {
        float i = floor(x);  // integer
        float f = fract(x);  // fraction
        // y = rand(i); //rand() is described in the previous chapter
        // float y = mix(rand(i), rand(i + 1.0), f);
        float y = mix(rand(i), rand(i + 1.0), smoothstep(0.,1.,f));
        return y;
      }
      void main() {
        float scale1 = (0.5 - animate / 2.) + animate * (0.5 * noise(noise(time * 2.) * 10.) + 0.03);
        float scale2 = (0.5 - animate / 2.) + animate * (0.5 * noise(noise(time * 2.) * 10. + 10.) + 0.03);
        float scale3 = (0.5 - animate / 2.) + animate * (0.5 * noise(noise(time * 2.) * 10. + 20.) + 0.03);
        vec4 circle1 = circle(distance(uv, orbit(0.15, 0.412) + vec2(0.05, 0.0)) * 2.0, vec4(0.0, 1.0, 1.0, scale1 + 0.2), scale1);
        vec4 circle2 = circle(distance(uv, orbit(-0.15, 0.634527)+ vec2(-0.05, 0.0)) * 2.0, vec4(1.0, 0.0, 1.0, scale2 + 0.2), scale2);
        vec4 circle3 = circle(distance(uv, orbit(0.1215, 1.0)) * 2.0, vec4(1.0, 1.0, 0.0, scale3 + 0.2), scale3);
        gl_FragColor = circle1 + circle2 + circle3;
      }
    `,
  },
});

export const AudioVisualizer: React.FC<{
  playing: boolean;
  loaded: boolean;
  source: string;
  safetyMode: boolean;
  animate: boolean;
}> = ({ playing, source, loaded, safetyMode, animate }) => {
  const [time, setTime] = useState(0);
  const [scale, setScale] = useState(0);
  const surfaceRef = useRef<{ gl: WebGLRenderingContext }>();
  const howlerRef = useRef<null | any>(null);
  const analyzerRef = useRef<null | AnalyserNode>(null);
  const htmlHowlRef = useRef<Howl | null>(null);
  const webAudioHowlRef = useRef<Howl | null>(null);

  useAnimationFrame(({ time }: { time: number }) => {
    if (analyzerRef.current) {
      //   let currentScale = Math.abs(
      //     buffer.getChannelData(0)[Math.floor(howl.seek() * buffer.sampleRate)]
      //   );
      const buffer = new Uint8Array(analyzerRef.current.frequencyBinCount);
      analyzerRef.current.getByteTimeDomainData(buffer);
      let currentScale = Math.abs(buffer[0]) / 255;
      currentScale = currentScale * 0.5 + (Math.sin(time / 4) + 1) * 0.1 + 0.1;
      //   currentScale = currentScale * 1;
      setScale(currentScale);
      //   amplitudeRef.current = currentScale;
    } else {
      setScale(0.25 + 0.1 + (Math.sin(time / 4) + 1) * 0.1);
    }
    setTime(time);
  }, []);

  // useEffect(() => {
  //   console.log({ sourceURL });
  //   if (sourceURL && howlerRef.current) {
  //     if (howlerRef.current.howler._sounds.length > 1) {
  //       console.log({ howler: Howler });
  //       howlerRef.current.howler._sounds[0]._node.disconnect();
  //       const node = howlerRef.current.howler._sounds[1]._node;
  //       const { context } = node;
  //       analyzerRef.current = context.createAnalyser();
  //       node.disconnect();
  //       node.connect(analyzerRef.current);
  //       console.log({ node });
  //     } else {
  //       console.log("no sounds");
  //     }
  //   }
  //   return () => {};
  // }, [sourceURL]);

  useEffect(() => {
    // console.log({ playing, source, loaded });
    // console.log("howls", (Howler as any)._howls);
    (
      Howler as HowlerGlobal & {
        _howls: (Howl & { _sounds: any[]; _src: string; _html5: boolean })[];
      }
    )._howls.forEach((howl, i, howls) => {
      function handleSource() {
        if (howl._src !== "/audio/silence.mp3" && !howl._html5) {
          // console.log(i, howl);
          webAudioHowlRef.current = howl;
          howl._sounds.forEach((sound, j, nodeSounds) => {
            // console.log(i, j, sound);
            const { _node: node } = sound;
            if (node) {
              if (node.disconnect) {
                // console.log("disconnecting", howl._src);
                node.disconnect();
              }
              if (
                j === nodeSounds.length - 1 &&
                howl._src === source &&
                howl.playing()
              ) {
                const { context } = node;
                analyzerRef.current = context.createAnalyser();
                node.connect(analyzerRef.current);
                // console.log("new analyser set");
              }
            }
          });
        } else if (howl._html5) {
          htmlHowlRef.current = howl;
        }
      }
      howl.on("unlock", () => {
        handleSource();
      });
      handleSource();
    });
  }, [playing, source, loaded]);

  useEffect(() => {
    if (surfaceRef.current) {
      const gl = surfaceRef.current.gl;
      // console.log({ gl });
      gl.clearColor(0.5, 0.5, 0.5, 1);
      gl.disable(gl.DEPTH_TEST);
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    }
  }, [surfaceRef.current]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (htmlHowlRef.current && webAudioHowlRef.current) {
        if (
          htmlHowlRef.current.playing() &&
          webAudioHowlRef.current.playing()
        ) {
          console.log("syncing");
          webAudioHowlRef.current.seek(htmlHowlRef.current.seek());
        }
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
    >
      <Surface ref={surfaceRef} width={300} height={300}>
        <Node
          shader={shaders.yugo}
          uniforms={{ time, animate: animate ? 1 : 0 }}
        />
      </Surface>
    </div>
  );
};
