/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { useContext } from "react";
import { currentPageContext } from "../hooks/current-page-context";
import { showHelpPopupContext } from "../hooks/popup-context";

export const HelpComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_HELP;
}> = ({ item }) => {
  const [showHelpPopup, setShowHelpPopup] = useContext(showHelpPopupContext);
  return (
    <div
      css={css`
        text-decoration: underline;
        cursor: pointer;
        margin-top: 64px;
        font-size: 18px;
        text-align: right;
      `}
      onClick={() => setShowHelpPopup && setShowHelpPopup(true)}
    >
      {item.text}
    </div>
  );
};
