export const addAudioToSequence = (
  page: Queries.IndexQuery["allStrapiPage"]["edges"][0]["node"],
  sequence: Queries.STRAPI__COMPONENT_CONTENT_AUDIO[]
) => {
  if (page.content) {
    for (const item of page.content) {
      if (item?.strapi_component === "content.audio") {
        // console.log(
        //   `adding ${
        //     (item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO).file?.localFile
        //       ?.publicURL
        //   }`
        // );
        sequence.push(item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO);
        // setAudioSequence([
        //   ...audioSequence,
        //   item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO,
        // ]);
      }
    }
  }
};

export const getNextPage = (
  page: Queries.IndexQuery["allStrapiPage"]["edges"][0]["node"]
) => {
  let nextPage = null;
  if (page.content) {
    for (const item of page.content) {
      if (item?.strapi_component === "content.audio") {
        nextPage = (item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO).page
          ? (item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO).page
          : nextPage;
      }
      if (item?.strapi_component === "content.button") {
        nextPage = (item as Queries.STRAPI__COMPONENT_CONTENT_BUTTON).page
          ? (item as Queries.STRAPI__COMPONENT_CONTENT_BUTTON).page
          : nextPage;
      }
      if (item?.strapi_component === "content.timer") {
        nextPage = (item as Queries.STRAPI__COMPONENT_CONTENT_TIMER).page
          ? (item as Queries.STRAPI__COMPONENT_CONTENT_TIMER).page
          : nextPage;
      }
      if (item?.strapi_component === "content.camera") {
        nextPage = (item as Queries.STRAPI__COMPONENT_CONTENT_CAMERA).page
          ? (item as Queries.STRAPI__COMPONENT_CONTENT_CAMERA).page
          : nextPage;
      }
      if (item?.strapi_component === "content.natural-cylinder") {
        nextPage = (item as Queries.STRAPI__COMPONENT_CONTENT_NATURAL_CYLINDER)
          .page
          ? (item as Queries.STRAPI__COMPONENT_CONTENT_NATURAL_CYLINDER).page
          : nextPage;
      }
    }
  }
  return nextPage ? nextPage.slug : null;
};

export const recursivelyAddToAudioSequence = (
  page: Queries.IndexQuery["allStrapiPage"]["edges"][0]["node"],
  sequence: Queries.STRAPI__COMPONENT_CONTENT_AUDIO[],
  pages: Queries.IndexQuery["allStrapiPage"]["edges"],
  history: string[] = []
) => {
  // console.log(`parsing ${page.slug}`);
  if (page.slug) {
    history.push(page.slug);
    addAudioToSequence(page, sequence);
    const nextPageSlug = getNextPage(page);
    const nextPage = pages.find((edge) => edge.node.slug === nextPageSlug);
    if (nextPageSlug && nextPage && !history.includes(nextPageSlug)) {
      recursivelyAddToAudioSequence(nextPage.node, sequence, pages, history);
    } else {
      // console.log(`did not find next page ${nextPageSlug}`);
    }
  }
};

export const toMMSS = (totalSeconds: number) => {
  const sec_num = Math.floor(totalSeconds);
  const minutes = Math.floor(sec_num / 60);
  const seconds = sec_num - minutes * 60;
  return (
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
};
