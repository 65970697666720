/** @jsx jsx */

import { jsx } from "@emotion/react";
import React, { useContext } from "react";
import { currentPageContext } from "../hooks/current-page-context";
import { Button } from "./button";

export const ButtonComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_BUTTON;
}> = ({ item }) => {
  const [currentPage, setCurrentPage] = useContext(currentPageContext);
  return (
    <Button
      onClick={() =>
        item.page &&
        item.page.slug &&
        setCurrentPage &&
        setCurrentPage(item.page.slug)
      }
    >
      {item.text}
    </Button>
  );
};
