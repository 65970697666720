/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { AudioComponent } from "../components/AudioComponent";
import { ButtonComponent } from "../components/ButtonComponent";
import { CameraComponent } from "../components/CameraComponent";
import { ImageComponent } from "../components/ImageComponent";
import { Layout } from "../components/Layout";
import { TextComponent } from "../components/TextComponent";
import { TextInputComponent } from "../components/TextInputComponent";
import { TimerComponent } from "../components/TimerComponent";
import { NaturalCylinder } from "./NaturalCylinder";
import { HelpComponent } from "./HelpComponent";

export const PageComponent: React.FC<{
  page: Queries.IndexQuery["allStrapiPage"]["edges"][0]["node"];
  setAudioId: (id: string | null) => void;
}> = ({ page, setAudioId }) => {
  const { content } = page;

  useEffect(() => {
    if (page.content) {
      let audioId = page.content.reduce((acc, item) => {
        if (item?.strapi_component === "content.audio") {
          return (item as Queries.STRAPI__COMPONENT_CONTENT_AUDIO).id;
        }
        return acc;
      }, null as null | string);
      setAudioId(audioId);
    }
  }, [page, setAudioId]);

  return (
    <Layout>
      {content &&
        content.map((item, i) => {
          if (!item) {
            return null;
          }
          console.log(item.strapi_component);
          return (
            <div key={`content-item-${i}`}>
              {item.strapi_component === "content.natural-cylinder" && (
                <NaturalCylinder
                  item={
                    item as Queries.STRAPI__COMPONENT_CONTENT_NATURAL_CYLINDER
                  }
                />
              )}
              {item.strapi_component === "content.text" && (
                <TextComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_TEXT}
                />
              )}
              {item.strapi_component === "content.button" && (
                <ButtonComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_BUTTON}
                />
              )}
              {item.strapi_component === "content.camera" && (
                <CameraComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_CAMERA}
                />
              )}
              {item.strapi_component === "content.image" && (
                <ImageComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_IMAGE}
                />
              )}
              {item.strapi_component === "content.text-input" && (
                <TextInputComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_TEXT_INPUT}
                />
              )}
              {item.strapi_component === "content.timer" && (
                <TimerComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_TIMER}
                />
              )}
              {item.strapi_component === "content.help" && (
                <HelpComponent
                  item={item as Queries.STRAPI__COMPONENT_CONTENT_HELP}
                />
              )}
            </div>
          );
        })}
    </Layout>
  );
};
