/** @jsx jsx */

import { jsx, css } from "@emotion/react";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { currentPageContext } from "../hooks/current-page-context";

export const TimerComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_TIMER;
}> = ({ item }) => {
  const secondsRef = useRef(item.seconds);
  const [seconds, setSeconds] = useState(item.seconds);
  const [currentPage, setCurrentPage] = useContext(currentPageContext);
  const [time, setTime] = useState({
    minutes: "0",
    seconds: "0",
    hundredths: "0",
  });

  useEffect(() => {
    if (seconds == null) {
      return;
    }
    setTime({
      seconds: _.padStart(Math.floor(seconds % 60).toString(), 2, "0"),
      minutes: _.padStart(Math.floor(seconds / 60).toString(), 2, "0"),
      hundredths: _.round(seconds % 1, 2)
        .toFixed(2)
        .toString()
        .substring(2),
    });
  }, [seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsRef.current !== null) {
        if (secondsRef.current > 0) {
          secondsRef.current = secondsRef.current - 0.01;
          if (secondsRef.current >= 0) {
            setSeconds(secondsRef.current);
          }
        } else {
          clearInterval(interval);
          if (item.page) {
            item.page &&
              item.page.slug &&
              setCurrentPage &&
              setCurrentPage(item.page.slug);
          }
        }
      }
    }, 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      css={css`
        font-family: "DSEG7Classic-Regular";
        font-size: 50px;
        text-align: center;
        color: #b8f5d0;
        background: black;
        padding: 16px;
        text-shadow: 0px 0px 10px #1ae26a;
        border-radius: 16px;
      `}
    >
      {time.minutes}:{time.seconds}
      <span
        css={css`
          font-size: 18px;
        `}
      >
        {time.hundredths}
      </span>
      {seconds !== null && item.seconds != null && (
        <div
          css={css`
            border-bottom: 2px solid #b8f5d0;
            /* width: ${100 * (seconds / item.seconds)}%; */
            margin-top: 8px;
            box-shadow: 0px 0px 10px 1px #1ae26a;
            height: 2px;
          `}
        ></div>
      )}
    </div>
  );
};
