/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import React, { Fragment } from "react";

export const ImageComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_IMAGE;
}> = ({ item }) => (
  <Fragment>
    {item &&
      item.image &&
      item.image.map((imageItem, i) =>
        imageItem ? (
          <img
            key={`image-${item.id}-${i}`}
            src={imageItem.localFile?.publicURL!}
            css={css`
              display: block;
              width: 100%;
            `}
          />
        ) : null
      )}
  </Fragment>
);
