/** @jsx jsx */
import React, { PropsWithChildren } from "react";
import { css, jsx } from "@emotion/react";

export const Button: React.FC<
  PropsWithChildren & {
    onClick: Function;
    inverted?: boolean;
    inline?: boolean;
    inactive?: boolean;
  }
> = ({ onClick, children, inverted, inline, inactive }) => (
  <div
    css={css`
      display: ${inline ? "inline-block" : "block"};
      width: auto;
      margin: 1em 0;
      user-select: none;
      font-size: 0.8em;
    `}
  >
    <span
      css={css`
        opacity: ${inactive ? 0.3 : 1};
        display: inline-block;
        border: 2px solid ${inverted ? "#fff" : "#000"};
        color: ${inverted ? "#fff" : "#000"};
        background-color: ${inverted ? "#000" : "#fff"};
        padding: 0.5em 0.5em;
        border-radius: 0.5em;
        line-height: 1;
        cursor: ${inactive ? "inherit" : "pointer"};
        text-align: center;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
        font-weight: bold;
      `}
      onClick={() => onClick && !inactive && onClick()}
    >
      {children}
    </span>
  </div>
);
