/** @jsx jsx */

import React from "react";
import { jsx, css } from "@emotion/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const TextComponent: React.FC<{
  item: Queries.STRAPI__COMPONENT_CONTENT_TEXT;
}> = ({ item }) =>
  item && item.content && item.content.data && item.content.data.content ? (
    <ReactMarkdown
      children={item.content.data.content}
      remarkPlugins={[remarkGfm]}
    />
  ) : null;
