import { useCallback, useState } from "react";
import { useLifecycles } from "react-use";

const isBrowser = typeof window !== "undefined";

function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement["addEventListener"]>)
    );
  }
}

function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args:
    | Parameters<T["removeEventListener"]>
    | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement["removeEventListener"]>)
    );
  }
}

/**
 * read and write url hash, response to url hash change
 */
export const useHash = () => {
  const [hash, setHash] = useState(() =>
    isBrowser ? window.location.hash : null
  );

  const onHashChange = useCallback(() => {
    setHash(isBrowser ? window.location.hash : null);
  }, []);

  useLifecycles(
    () => {
      isBrowser && on(window, "hashchange", onHashChange);
    },
    () => {
      isBrowser && off(window, "hashchange", onHashChange);
    }
  );

  const _setHash = useCallback(
    (newHash: string) => {
      if (isBrowser && newHash !== hash) {
        window.location.hash = newHash;
      }
    },
    [hash]
  );

  return [hash, _setHash] as const;
};
